<template>
  <div>
    <el-card class="box-card card-contanier">
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <div style="float: left; width: 94%">
            <el-form-item label="门店名称：">
              <el-input
                v-model="searchForm.soeName"
                placeholder="门店名称"
                size="medium"
                clearable
                @keyup.enter.native="search">
              </el-input>
            </el-form-item>

            <el-form-item>
              <el-button @click="search" icon="el-icon-search" size="medium" >搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="refresh" icon="el-icon-refresh" size="medium">重置</el-button>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button
              type="primary"
              @click="addStore"
              size="medium"
              v-if="hasAuth('sy:store:add')">新增
              </el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      v-loading="isLoading"
      border
      stripe
      size="small"
      style="margin-top: 15px">
      <el-table-column
        type="index"
        label="序号"
        width="50"
        align="center"
        fixed>
      </el-table-column>
      <el-table-column prop="soeName" label="门店名称" align="center" show-overflow-tooltip fixed>
      </el-table-column>
      <el-table-column prop="soeNum" label="门店编号" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="soeContact" label="门店联系人" align="center">
      </el-table-column>
      <el-table-column
        prop="soePhone"
        label="联系人电话"
        align="center"
        width="120">
      </el-table-column>
      <el-table-column
        prop="areaname"
        label="门店地址"
        align="center"
        width="200"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="mgName" label="所属商户" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="soeStat" label="门店状态" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.soeStat === 0" type="success">营业</el-tag>
          <el-tag v-else type="warning">歇业</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="soeSgName" label="所属分组" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="操作" align="center" width="300" fixed="right">
        <template slot-scope="scope">

          <el-button type="text" @click="endOutUser(scope.row.soeId)">查看员工</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button type="text" @click="createQrcode(scope.row)">门店二维码</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button type="text" @click="editStore(scope.row)"  v-if="hasAuth('sy:store:edit')">编辑</el-button>
          <el-divider direction="vertical"  v-if="hasAuth('sy:store:edit')"></el-divider>
          <el-popconfirm
            title="确认删除选中门店？"
            icon="el-icon-info"
            icon-color="red"
            @confirm="deleteStore(scope.row.soeId)"
            v-if="hasAuth('sy:store:delete')">
            <el-button type="text" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100]"
      :current-page="current"
      :page-size="size"
      :total="total"
      style="float: right">
    </el-pagination>

    <!-- 新增修改弹窗 -->
    <el-dialog
      :title="isAdd ? '新增' : '修改-' + titleName + '门店'"
      :visible.sync="addDialogVisible"
      width="650px"
      :close-on-click-modal="false"
      :before-close="cancelEdit">
      <div v-loading="dialogLoading" class="merchant-form">
        <el-form
          :model="ruleForm"
          :rules="ruleFormRules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm" >
          <el-form-item label="门店logo：" prop="soeLogFile">
            <el-upload
              action="#"
              list-type="picture-card"
              :on-preview="handleStoreLogoPreview"
              :on-remove="handleStoreLogoRemove"
              :auto-upload="false"
              :on-change="handleStoreLogoChange"
              :class="objClass"
              :file-list="soeLogoList"
              :limit="1">
              <i class="el-icon-plus"></i>
            </el-upload>
            <!-- el-dialog为点击预览图的放大按钮后弹出来的框，在框内显示放大的图片 -->
            <el-dialog :visible.sync="imageDialogVisible" append-to-body>
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>
          <el-form-item label="门店名称：" prop="soeName" label-width="100px">
            <el-input
              v-model="ruleForm.soeName"
              placeholder="请输入门店名称"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="门店编号：" prop="soeNum" label-width="100px">
            <el-input
              v-model="ruleForm.soeNum"
              placeholder="请输入门店编号"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人：" prop="soeContact" label-width="100px">
            <el-input
              v-model="ruleForm.soeContact"
              placeholder="请输入门店联系人"
              maxlength="25"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话：" prop="soePhone" label-width="100px">
            <el-input
              v-model="ruleForm.soePhone"
              placeholder="请输入联系电话"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="门店状态：" prop="soeStat" label-width="100px">
            <el-radio-group v-model="ruleForm.soeStat">
              <el-radio :label="0">营业</el-radio>
              <el-radio :label="1">歇业</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="所在地区：" prop="soeArea" label-width="100px">
            <el-cascader
              v-model="ruleForm.soeArea"
              :options="areaOptions"
              :props="{ value: 'regionId', label: 'regionName' }"
            ></el-cascader>
          </el-form-item>
          <!--TODO 所属商盟 当前就小睿一个商户，所以商盟暂时不做-->
          <el-form-item label="详细地址：" prop="soeAddr" label-width="100px">
            <el-input
              v-model="ruleForm.soeAddr"
              placeholder="请输入详细地址"
              type="textarea"
              maxlength="500"
              show-word-limit
              :autosize="{ minRows: 3, maxRows: 6 }"
            ></el-input>
          </el-form-item>
          <!--TODO 地图选择器-->
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-button type="primary" @click="onSubmit()">保存</el-button>
          <el-button @click="cancelEdit()">取消</el-button>
        </div>
      </div>
    </el-dialog>

  <!-- 查看员工弹框 -->
    <el-dialog
            title="信息"
            :visible.sync="dialogEndOutUser"
            width="70%"
            :before-close="endOutUserClose"
            :close-on-click-modal="false">
            <el-card class="card-contanier">
                <div class="card-content">
                    <el-form :inline="true"
                     @submit.native.prevent>
                        <div style="float:left;width: 90%">
                            <el-form-item label="员工姓名:">
                                <el-input v-model="name" clearable placeholder="员工姓名" size="medium"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button @click="searchEndOutUser" icon="el-icon-search" size="medium">搜索</el-button>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
            </el-card>
            <el-table ref="multipleTable" 
                :data="personnelData"
                tooltip-effect="dark"
                border
                style="width: 100%"
                stripe
                size="small"
                :row-style="{ height: '40px' }"
                :cell-style="{ padding: '1px 0' }">
                <el-table-column type="index" label="序号" align="center">
                </el-table-column>
                <el-table-column  align="center" property="name" label="员工姓名"></el-table-column>
                <el-table-column  align="center" property="phone" label="联系方式"></el-table-column>
                <el-table-column label="操作" align="center" width="300" fixed="right">
                  <template slot-scope="scope">
                 <el-button type="text" @click="createQrcode1(scope.row)">员工二维码</el-button>
                  </template>
                </el-table-column>
                
            </el-table>
            <div style="margin-top: 30px;">

            </div>
        </el-dialog>
  

    <!-- 门店二维码弹窗 -->
    <el-dialog
      :title="codetitle"
      :visible.sync="qrcodeDialog"
      width="450px"
      :close-on-click-modal="false"
      :before-close="cancelQrcode">
      <div v-loading="qrcodeDialogLoading" class="qrcode">
        <qrcode :text="qrcodeUrl" :size="300" ref="qrcode"></qrcode>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="copyAddressUrl()">复制链接</el-button>
        <el-button @click="downQRImg()">点击下载</el-button>
      </div>
    </el-dialog>

    <!-- 员工二维码弹窗 -->
    <el-dialog
      :title="codetitle1"
      :visible.sync="qrcodeDialog1"
      width="450px"
      :close-on-click-modal="false"
      :before-close="cancelQrcode1">
      <div v-loading="qrcodeDialogLoading1" class="qrcode">
        <qrcode :text="qrcodeUrl1" :size="300" ref="qrcode"></qrcode>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="copyAddressUrl1()">复制链接</el-button>
        <el-button @click="downQRImg1()">点击下载</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "../../api/stroe";
import config from "../../config";
import userApi from "../../api/user";
export default {
  name: "Store",
  data() {
    return {
      seoId :"",//员工Id
      phone:"",//手机号
      name:'',//员工姓名
      dialogEndOutUser:false,
      personnelData:[],
      searchForm: {
        soeName: "",
      },
      ranks: "",
      editId: "",
      tableData: [],
      total: 0,
      size: 10,
      current: 1,
      isAdd: true,
     
      addDialogVisible: false,
      isLoading: false,
      dialogLoading: false,

      codetitle: "",
      codetitle1: "",

      qrcodeDialog: false,
      qrcodeDialog1: false,

      qrcodeUrl: "",
      qrcodeUrl1:"",

      qrcodeDialogLoading: false,
      qrcodeDialogLoading1: false,

      dialogImageUrl: "",
      imageDialogVisible: false,
      titleName: "",
      soeLogoList: [],
      objClass: {
        upLoadShow: true,
        upLoadHide: false,
      },
      ruleForm: {
        soeLogFile: null,
        soeName: "",
        soeContact: "",
        soePhone: "",
        soeStat: "",
        soeArea: "",
        soeAddr: "",
        soeNum: "",
      },
      ruleFormRules: {
        soeName: [
          { required: true, message: "请输入门店名称", trigger: "blur" },
        ],
        soeNum: [
          { required: true, message: "请输入门店编号", trigger: "blur" },
          {
            pattern:"^[A-Za-z0-9]+$",
            message:"请输入字母或数字",
            trigger: "blur",
          }
        ],
        soeContact: [
          { required: true, message: "请输入门店联系人", trigger: "blur" },
        ],
        soeStat: [
          { required: true, message: "请选择门店状态", trigger: "blur" },
        ],
        soeAddr: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        soePhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          {
            pattern: "^1[3|4|5|7|8][0-9]{9}$",
            message: "请输入正确的联系电话",
            trigger: "blur",
          },
        ],
        soeArea: [{ required: true, message: "选择地区", trigger: "blur" }],
      },
      areaOptions: [],
      soeId: "",
      type: "STORE",
      user:"USER",
    };
  },
  created() {
    this.getStoreList();
    this.getAllAreaInfo();
    userApi.userinfo().then((res) => {
      this.ranks = res.data.data.result.ranks;
    });
  },
  methods: {
    /* 搜索员工名 */
    searchEndOutUser(){
      this.endOutUser(this.seoId);
    },
    endOutUserClose(){
        this.dialogEndOutUser = false;
     },
    //查看员工按钮
    endOutUser(seoId){
       this.seoId = seoId;
        store.selectById(this.seoId, this.name).then(res =>{
           this.personnelData = res.data.data.result;
       })
          this.dialogEndOutUser = true;

    },

     //员工二维码
     createQrcode1(row) {
      this.codetitle1 = "员工名称：" + row.name;
      this.qrcodeDialog1 = true;
      this.qrcodeDialogLoading1 = true;
      this.userId= row.userId;
      this.qrcodeUrl1 = `http://shang.xiaoruizhixi.com/qrcode-boot/api/test/pushactivecards/${this.user
        .concat("-")
        .concat(this.userId)}`;
      this.qrcodeDialogLoading1 = false;
    },
    //员工二维码复制
    copyAddressUrl1() {
      let oInput = document.createElement("input");
      //这边为链接地址this.liveLink='www.baidu.com'
      oInput.value = `http://shang.xiaoruizhixi.com/qrcode-boot/api/test/pushactivecards/${this.user
        .concat("-")
        .concat(this.userId)}`;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.remove();
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    //员工二维码下载
    downQRImg1() {
      const codeurl1 = this.$refs.qrcode.$el.src;
      let a = document.createElement("a");
      let event = new MouseEvent("click");
      a.download = this.codetitle1;
      a.href = codeurl1;
      a.dispatchEvent(event);
    },
    
    getStoreList() {
      this.isLoading = true;
      let params = {
        soeName: this.searchForm.soeName,
        current: this.current,
        size: this.size,
      };
      store.getStoreList(params).then((res) => {
        this.tableData = res.data.data.result.records;
        this.size = res.data.data.result.size;
        //this.current = res.data.data.result.current;
        this.total = res.data.data.result.total;
        this.isLoading = false;
      });
    },
    
    getAllAreaInfo() {
      store.getAllArea().then((res) => {
        this.areaOptions = res.data.data.result;
      });
    },
    search() {
      this.current = 1;
      this.getStoreList();
    },
    refresh() {
      this.searchForm = { soeName: "" };
      this.current = 1;
      this.getStoreList();
    },
   
    handleSizeChange(val) {
      this.size = val;
      this.getStoreList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getStoreList();
    },
    addStore() {
      // this.resetForm("ruleForm");
      this.isAdd = true;
      this.addDialogVisible = true;
    },
    // resetForm(formName) {
    //   if (this.$refs[formName] != null && this.$refs[formName] !== undefined) {
    //     this.$refs[formName].resetFields();
    //   }
    // },
    // 点击预览图的放大按钮后会触发handlePictureCardPreview
    handleStoreLogoPreview(file) {
      this.dialogImageUrl = file.url;
      this.imageDialogVisible = true;
    },
    handleStoreLogoRemove(file, fileList) {
      this.objClass.upLoadShow = true; //删除图片后显示上传框
      this.objClass.upLoadHide = false;
      this.dialogImageUrl = "";
      this.ruleForm.soeLogFile = null;
    },
    handleStoreLogoChange(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      // .jpg,.jpeg,.png,.pdf,.PDF
      const extension1 = testmsg === "jpg";
      const extension2 = testmsg === "jpeg";
      const extension3 = testmsg === "png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!extension1 && !extension2 && !extension3) {
        this.$message({
          message: "上传的图片只能是.jpg,.jpeg,.png格式!",
          type: "warning",
        });
        this.soeLogoList = [];
      } else if (!isLt2M) {
        this.$message({
          message: "上传的图片大小不能超过2M!",
          type: "warning",
        });
        this.soeLogoList = [];
      } else {
        this.objClass.upLoadHide = true; //上传图片后置upLoadHide为真，隐藏上传框
        this.objClass.upLoadShow = false;
        this.dialogImageUrl = file.url;
        this.ruleForm.soeLogFile = file.raw;
      }
    },
    cancelEdit() {
      this.addDialogVisible = false;
      this.dialogImageUrl = "";
      this.ruleForm.soeLogFile = null;
      this.objClass.upLoadHide = false; //上传图片后置upLoadHide为真，隐藏上传框
      this.objClass.upLoadShow = true;
      this.ruleForm.soeName = "";
      this.ruleForm.soeContact = "";
      this.ruleForm.soePhone = "";
      this.ruleForm.soeStat = "";
      this.ruleForm.soeArea = "";
      this.ruleForm.soeAddr = "";
      this.ruleForm.soeNum = "";
      this.soeLogoList = [];
    },
    //编辑
    editStore(row) {
      console.log(row)
      this.isAdd = false;
      this.editId = row.soeId;
      this.isLoading = true;
      store.getInfoById(row.soeId).then((res) => {
        let storeInfo = res.data.data.result;
        if (storeInfo.soeLog) {
          let soeLogoUrl = config.baseUrl + "/" + storeInfo.soeLog;
          this.soeLogoList = [{ url: soeLogoUrl }];
          this.objClass.upLoadHide = true; //上传图片后置upLoadHide为真，隐藏上传框
          this.objClass.upLoadShow = false;
          this.dialogImageUrl = soeLogoUrl;
          this.ruleForm.soeLogFile = "noEdit";
        }
        this.ruleForm.soeName = storeInfo.soeName;
        this.ruleForm.soeContact = storeInfo.soeContact;
        this.ruleForm.soePhone = storeInfo.soePhone;
        this.ruleForm.soeStat = storeInfo.soeStat;
        if (storeInfo.soeArea) {
          this.ruleForm.soeArea = storeInfo.soeArea.split(",");
        }
        this.ruleForm.soeAddr = storeInfo.soeAddr;
        this.ruleForm.soeNum = storeInfo.soeNum;
        this.titleName = storeInfo.soeName;
        this.isLoading = false;
        this.addDialogVisible = true;
      });
    },
    //保存
    onSubmit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.dialogLoading = true;
          let formData = new FormData();
          formData.append("soeLogFile", this.ruleForm.soeLogFile);
          formData.append("soeName", this.ruleForm.soeName);
          formData.append("soeContact", this.ruleForm.soeContact);
          formData.append("soePhone", this.ruleForm.soePhone);
          formData.append("soeArea", this.ruleForm.soeArea);
          formData.append("soeStat", this.ruleForm.soeStat);
          formData.append("soeAddr", this.ruleForm.soeAddr);
          formData.append("soeId", this.editId);
          formData.append("soeNum", this.ruleForm.soeNum);
          store
            .changeStoreInfo(this.isAdd ? "add" : "edit", formData)
            .then((res) => {
              if (res.data.code === 200) {
                this.$notify({
                  title: "操作成功",
                  message: "",
                  position: "bottom-right",
                  type: "success",
                });
                this.dialogLoading = false;
                this.addDialogVisible = false;
                this.getStoreList();
              }
            })
            .catch((e) => {
              this.dialogLoading = false;
            });
        }
      });
    },
    deleteStore(soeId) {
      store.deleteStoreById(soeId).then((res) => {
        if (res.data.code === 200) {
          this.$notify({
            title: "prefect",
            message: "删除成功",
            type: "success",
            position: "bottom-right",
          });
        } else {
          this.$notify({
            title: "prefect",
            message: "删除失败",
            type: "error",
            position: "bottom-right",
          });
        }
        this.getStoreList();
      });
    },
    createUser(){

    },
   //门店二维码
    createQrcode(row) {
      
      this.codetitle = "门店名称：" + row.soeName;
      this.qrcodeDialog = true;
      this.qrcodeDialogLoading = true;
      this.soeId = row.soeId;
      this.qrcodeUrl = `http://shang.xiaoruizhixi.com/qrcode-boot/api/test/pushactivecards/${this.type
        .concat("-")
        .concat(this.soeId)}`;
      this.qrcodeDialogLoading = false;
    },
   
    //门店复制二维码
    copyAddressUrl() {
      let oInput = document.createElement("input");
      //这边为链接地址this.liveLink='www.baidu.com'
      oInput.value = `http://shang.xiaoruizhixi.com/qrcode-boot/api/test/pushactivecards/${this.type
        .concat("-")
        .concat(this.soeId)}`;
      document.body.appendChild(oInput);
      oInput.select();
      console.log(oInput.value);
      document.execCommand("Copy");
      oInput.remove();
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },


    // 点击触发下载二维码
    downQRImg() {
      const codeurl = this.$refs.qrcode.$el.src;
      let a = document.createElement("a");
      let event = new MouseEvent("click");
      a.download = this.codetitle;
      a.href = codeurl;
      a.dispatchEvent(event);
    },
    cancelQrcode() {
      this.qrcodeDialog = false;
      this.qrcodeDialogLoading = false;

    },
    cancelQrcode1() {
      this.qrcodeDialog1 = false;
      this.qrcodeDialogLoading1 = false;
    },
  },
  
};
</script>
<style scoped>
.el-pagination {
  margin-top: 20px;
  text-align: right;
}
.qrcode {
  align-items: center;
  text-align: center;
}
</style>
<style>
/*当upLoadHide为true时，启用如下样式，即上传框的样式，若为false则不启用该样式*/
.merchant-form .upLoadHide .el-upload {
  display: none;
}
</style>
